import { render, staticRenderFns } from "./PoolDetail.vue?vue&type=template&id=dcc670b6&scoped=true"
import script from "./PoolDetail.vue?vue&type=script&lang=js"
export * from "./PoolDetail.vue?vue&type=script&lang=js"
import style0 from "./PoolDetail.vue?vue&type=style&index=0&id=dcc670b6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.31_cache-loader@4.1.0_webpack@4.47.0_webpack-cli@5.1_gufug4oqq62cm4nx5loipnfjtu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcc670b6",
  null
  
)

export default component.exports